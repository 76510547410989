import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => {
  const redirect = () => {
    return (
      <Link style={{ color: '#F9A826', fontSize: '17px' }} to="/">
        Retour à la page d'accueil
      </Link>
    )
  }
  return (
    <Layout title="404" desc="Page introuvable" svg="error" redirect={redirect}>
      <SEO title="404 : Introuvable" />
    </Layout>
  )
}

export default NotFoundPage
